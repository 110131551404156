import React, { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Cost() {
  const [value, setValue] = useState("");
  const [costAudioMinutes, setCostAudioMinutes] = useState(0);
  const [costAudioSeconds, setCostAudioSeconds] = useState(0);
  const [multiplication, setMultiplication] = useState(8);
  const [finalCostMessage, setFinalCostMessage] = useState("");
  const [finalCostAudio, setFinalCostAudio] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [replyType, setReplyType] = useState("text");

  const formatCurrency = (amount) => {
    if (amount >= 3 && amount <= 10) {
      return `${amount} دراهم`;
    } else if (amount >= 11 && amount <= 99) {
      return `${amount} درهماً`;
    } else {
      return `${amount} درهم`;
    }
  };

  const costMessage = (text) => {
    const costText = Math.floor((text.target.value.length / 70) * multiplication);
    setValue(text.target.value);
    setFinalCostMessage(formatCurrency(costText));
  };

  const handleReplyTypeChange = (e) => {
    const type = e.target.value;
    setReplyType(type);
    setMultiplication(type === "text" ? 8 : 12);
    const newCost = Math.floor((value.length / 70) * (type === "text" ? 8 : 12));
    setFinalCostMessage(formatCurrency(newCost));
  };

  const handleAudioInputChange = (e, unit) => {
    const val = e.target.value;
    if (unit === "minutes") {
      setMinutes(val);
      const totalMinutesCost = Math.floor(((val * 60) / 10) * 12);
      setCostAudioMinutes(totalMinutesCost);
    } else {
      setSeconds(val);
      const totalSecondsCost = Math.floor((val / 10) * 12);
      setCostAudioSeconds(totalSecondsCost);
    }
  };

  useEffect(() => {
    const totalCost = costAudioMinutes + costAudioSeconds;
    setFinalCostAudio(formatCurrency(totalCost));
  }, [costAudioMinutes, costAudioSeconds]);

  const notify = () => toast("تم النسخ إلى الحافظة", {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const getTextToCopy = () => {
    switch (replyType) {
      case 'text':
        return `التكلفة الإجمالية لتفسير حلمك كتابياً = ${finalCostMessage}\n` +
          `تفاصيل تكلفة تفسير الحلم عبر وتساب 0507354835\n` +
          `كل 70 حرفاً =  ${formatCurrency(multiplication)}\n` +
          `عدد الحروف = ${value.length}`;
      case 'text_voice':
        return `التكلفة الإجمالية لتفسير حلمك صوتياً = ${finalCostMessage}\n` +
          `تفاصيل تكلفة تفسير الحلم عبر وتساب 0507354835\n` +
          `كل 70 حرفاً =  ${formatCurrency(multiplication)}\n` +
          `عدد الحروف = ${value.length}`;
      case 'audio':
        return `التكلفة الإجمالية لتفسير حلمك صوتياً = ${finalCostAudio}\n` +
          `تفاصيل تكلفة تفسير الحلم عبر وتساب 0507354835\n` +
          `كل 10 ثوان صوتية = 12 درهماً\n` +
          `مدة التسجيل = ${minutes || "0"}:${seconds ? (seconds < 10 ? "0" + seconds : seconds) : "00"}`;
      default:
        return "";
    }
  };

  return (
    <div className="container-fluid align-items-center justify-content-center flex-column d-flex">
      <div className="w-100 align-items-center justify-content-center mb-5 d-flex mt-5">
        <div className="mb-3">
          <input
            style={{ marginRight: 10, cursor: "pointer" }}
            type="radio"
            id="textReply"
            name="replyType"
            value="text"
            checked={replyType === "text"}
            onChange={handleReplyTypeChange}
          />
          <label htmlFor="textReply">نص</label>
          <input
            style={{ marginRight: 10, cursor: "pointer" }}
            type="radio"
            id="textVoiceReply"
            name="replyType"
            value="text_voice"
            checked={replyType === "text_voice"}
            onChange={handleReplyTypeChange}
          />
          <label htmlFor="textVoiceReply">نص و الجواب صوت</label>
          <input
            style={{ marginLeft: 10, cursor: "pointer" }}
            type="radio"
            id="audioReply"
            name="replyType"
            value="audio"
            checked={replyType === "audio"}
            onChange={handleReplyTypeChange}
          />
          <label htmlFor="audioReply">صوت</label>
        </div>
      </div>

      <CopyToClipboard text={getTextToCopy()} onCopy={notify}>
        <button>نسخ</button>
      </CopyToClipboard>

      {replyType === "text" && (
        <>
          <TextareaAutosize
            dir="rtl"
            style={{ textAlign: "right" }}
            className="form-control w-50 mb-3"
            maxRows={10}
            onChange={costMessage}
            value={value}
            placeholder="أدخل النص هنا"
          />
          {value && (
            <i
              onClick={() => {
                setValue("");
                setFinalCostMessage("");
              }}
              className="fa fa-times-circle"
              aria-hidden="true"
              style={{
                color: "red",
                fontSize: 30,
                paddingLeft: 15,
                cursor: "pointer",
              }}
            ></i>
          )}
          <div dir="rtl">
            <p>التكلفة الإجمالية لتفسير حلمك كتابياً = {finalCostMessage}</p>
            <p>تفاصيل تكلفة تفسير الحلم عبر وتساب 0507354835</p>
            <p>كل 70 حرفاً =  {formatCurrency(multiplication)}</p>
            <p>عدد الحروف = {value.length}</p>
          </div>
        </>
      )}

      {replyType === "text_voice" && (
        <>
          <TextareaAutosize
            dir="rtl"
            style={{ textAlign: "right" }}
            className="form-control w-50 mb-3"
            maxRows={10}
            onChange={costMessage}
            value={value}
            placeholder="أدخل النص هنا"
          />
          {value && (
            <i
              onClick={() => {
                setValue("");
                setFinalCostMessage("");
              }}
              className="fa fa-times-circle"
              aria-hidden="true"
              style={{
                color: "red",
                fontSize: 30,
                paddingLeft: 15,
                cursor: "pointer",
              }}
            ></i>
          )}
          <div dir="rtl">
            <p>التكلفة الإجمالية لتفسير حلمك صوتياً = {finalCostMessage}</p>
            <p>تفاصيل تكلفة تفسير الحلم عبر وتساب 0507354835</p>
            <p>كل 70 حرفاً =  {formatCurrency(multiplication)}</p>
            <p>عدد الحروف = {value.length}</p>
          </div>
        </>
      )}

      {replyType === "audio" && (
        <div className="d-flex mb-4" style={{ gap: "10px" }}>
          <div style={{ width: 100 }}>
            <input
              max={59}
              min={0}
              type="number"
              className="form-control"
              placeholder="دقائق"
              onChange={(e) => handleAudioInputChange(e, "minutes")}
              value={minutes}
            />
          </div>
          <div style={{ width: 100 }}>
            <input
              max={59}
              min={0}
              type="number"
              className="form-control"
              placeholder="ثوان"
              onChange={(e) => handleAudioInputChange(e, "seconds")}
              value={seconds}
            />
          </div>
          <div dir="rtl">
            <p>التكلفة الإجمالية لتفسير حلمك صوتياً = {finalCostAudio}</p>
            <p>تفاصيل تكلفة تفسير الحلم عبر وتساب 0507354835</p>
            <p>كل 10 ثوان صوتية = 12 درهماً</p>
            <p>مدة التسجيل = {minutes || "0"}:{seconds ? (seconds < 10 ? "0" + seconds : seconds) : "00"}</p>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default Cost;
