import React from "react";
import Cost from "./Cost";

function App() {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Cost />
    </div>
  );
}

export default App;
